var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Position" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codePosition) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", { staticClass: "mb-0" }, [
        _c("span", [
          _vm._v("Position of the toast on the screen. Can be any of ")
        ]),
        _c("code", [_vm._v("top-right")]),
        _c("span", [_vm._v(" , ")]),
        _c("code", [_vm._v("top-center")]),
        _c("span", [_vm._v(" , ")]),
        _c("code", [_vm._v("top-left")]),
        _c("span", [_vm._v(" , ")]),
        _c("code", [_vm._v("bottom-right")]),
        _c("span", [_vm._v(" , ")]),
        _c("code", [_vm._v("bottom-center")]),
        _c("span", [_vm._v(" , ")]),
        _c("code", [_vm._v("bottom-left")]),
        _vm._v(". ")
      ]),
      _c("h5", { staticClass: "mb-0" }, [_vm._v(" Top Positions ")]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.showToast("primary", "top-right")
                }
              }
            },
            [_vm._v(" Top Right ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.showToast("secondary", "top-left")
                }
              }
            },
            [_vm._v(" Top Left ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.showToast("success", "top-center")
                }
              }
            },
            [_vm._v(" Top Center ")]
          )
        ],
        1
      ),
      _c("h5", { staticClass: "mt-2 mb-0" }, [_vm._v(" Bottom Positions ")]),
      _c(
        "div",
        { staticClass: "demo-inline-spacing" },
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.showToast("danger", "bottom-right")
                }
              }
            },
            [_vm._v(" Bottom Right ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.showToast("warning", "bottom-left")
                }
              }
            },
            [_vm._v(" Bottom Left ")]
          ),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "ripple",
                  rawName: "v-ripple.400",
                  value: "rgba(113, 102, 240, 0.15)",
                  expression: "'rgba(113, 102, 240, 0.15)'",
                  modifiers: { "400": true }
                }
              ],
              attrs: { variant: "outline-primary" },
              on: {
                click: function($event) {
                  return _vm.showToast("info", "bottom-center")
                }
              }
            },
            [_vm._v(" Bottom Center ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }